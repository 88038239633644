import * as React from "react"
import type { HeadFC } from "gatsby"
import VerticalCenter from "../components/VerticalCenter"

const subpages = [
  {
      name: "Projects",
      path: "/projects"
  },
  {
      name: "About",
      path: "/about"
  }
]

const subheaderStyle: React.CSSProperties = {
  textDecoration: 'none',
  color: "#eeeeee",
  marginLeft: '10px',
  marginRight: '10px'
}

const headerStyles: React.CSSProperties = {
    fontSize: '400%',
    textDecoration: 'none',
    color: '#eeeeee'

}

const containerStyles: React.CSSProperties = {
  paddingBottom: '50px'
}

const links = [
 
]

const Nav = () => {
  return (
    <div style={containerStyles}>
      <a href="/" style={headerStyles}>
          Joshua LaBue
      </a>

      <h2>
      {
          subpages.map((page) => {
              return <a style={subheaderStyle} href={page.path} key={page.name}>{page.name}</a>
          })
      }
      </h2>
    </div>
  )
}

export default Nav