import React from 'react';

import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"

const iconLinkStyles: React.CSSProperties = { 
    fontSize: '200%'
}

const footerAStyles: React.CSSProperties = {
    color: '#ffffff',
    paddingLeft: '5px',
    paddingRight: '5px'
}

const links = [
    {
        key: 'github',
        icon: FaGithub,
        address: "https://github.com/joshlabue"
    },
    {
        key: 'email',
        icon: FaEnvelope,
        address: "mailto:me@joshlabue.me"
    }
]

const Footer  = () => {
    return <div>
        {links.map(link => {
            return <a style={footerAStyles} href={link.address} key={link.key}>
                <link.icon style={iconLinkStyles}></link.icon>
            </a>
        })}
    </div>
};

export default Footer;