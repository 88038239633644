import React, { CSSProperties } from "react";

const objectStyles: CSSProperties = {
    margin: 0,
    // position: "absolute",
    // top: '50%',
    // left: '50%',
    // transform: 'translateX(-50%)',
    // height: 'auto'
}

const VerticalCenter = (props: any) => {
    return <div style={objectStyles}>
        {props.children}
    </div>
}

export default VerticalCenter;