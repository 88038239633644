import * as React from "react"
import type { HeadFC } from "gatsby"
import VerticalCenter from "../components/VerticalCenter"
import Nav from "../components/Nav"

// import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap"

import '../base.css'
import Footer from "../components/Footer"

const pageStyles = {

}

const subpages = [
    {
        name: "About",
        path: "/about"
    }
]

const headerStyles: React.CSSProperties = {
    fontSize: '400%'
}

const iconStyle: React.CSSProperties = {
    verticalAlign: 'middle',
    marginRight: '10px'
}

const factStyles: React.CSSProperties = {
    fontSize: '1.2em',
    // fontWeight: 'bold',
}

const pageContent: React.CSSProperties = {
    paddingBottom: '75px'
}

const AboutPage = () => {
  return (
    <main style={pageStyles}>
        <VerticalCenter>
            <Nav/>
            <div style={pageContent}>
                <span style={factStyles} >
                    <FaGraduationCap style={iconStyle}/> <br/>
                    <span>
                   Clarkson University <br/>
                    Computer Engineering 
                    <br/>
                    Class of 2024</span>
                </span>
            </div>

            <Footer />
        </VerticalCenter>
    </main>
  )
}

export default AboutPage

export const Head: HeadFC = () => <title>About - Joshua LaBue</title>
